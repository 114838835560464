 .footer {
    z-index: 2;
    display: table;
    position: absolute;
    height: 13%;
  
    text-align: center;
    background-color: #263343;
    color: #35373e;
    width: 100%;
    bottom: 0;
  }

  .footer a {
    text-decoration: none;
    padding-right: 1vh;
  
    color: white;
  }
  .footer a:visited {
    text-decoration: none;
  }
  .footer a:hover {
    text-decoration: none;
  }
  .footer a:focus {
    text-decoration: none;
  }
  .footer a:hover,
  .footer a:active {
    text-decoration: none;
  }
  
  .footer_inner {
    display: table-cell;
    vertical-align: middle;
  }

  .footer_inner .api_by {
    color: #777777;
  }