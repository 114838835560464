@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif;
    background-color: #263343;
}

.main_page {
    margin-top: 50px;
    text-align: center;
}

.main_text {
    font-size: 32px;
    color: white;
}

.main_text .ip_country {
    font-size: 40px;
    font-weight: bold;
}

#map {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10% 0 0 -25%;
}

@media only screen and (max-width: 768px) {
    #map{
        width: 70%;
        height: 40%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -13% 0 0 -35%;
    }
}

@media only screen and (max-width: 375px) {
    #map{
        width: 80%;
        height: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 15% 0 0 -40%;
    }
}

#kakaoAddr {
    display:block;
    margin-top:2px;
    font-weight: normal;
}