.loader {
  display: table;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #191f26;
}

.loader_gif {
  display: table-cell;
  vertical-align: middle;
}