body {
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}

.header_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #263343;
  padding: 8px 12px;
}

.header_title {
  font-size: 24px;
  color: white;
}

.header_title i {
  color: #ffffff;
  margin-right: 10px;
}

.header_menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .header_menu{
    flex-direction: row;
  }
}

.header_menu a {
  display: block;
  text-align: center;
  margin: 5px;
  padding: 10px 5px;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.4;
  color: white;
}

.header_menu a:hover {
  color: #77c988;
}