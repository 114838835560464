@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}

.header_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #263343;
  padding: 8px 12px;
}

.header_title {
  font-size: 24px;
  color: white;
}

.header_title i {
  color: #ffffff;
  margin-right: 10px;
}

.header_menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .header_menu{
    flex-direction: row;
  }
}

.header_menu a {
  display: block;
  text-align: center;
  margin: 5px;
  padding: 10px 5px;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.4;
  color: white;
}

.header_menu a:hover {
  color: #77c988;
}
body {
    font-family: 'Noto Sans KR', sans-serif;
    background-color: #263343;
}

.main_page {
    margin-top: 50px;
    text-align: center;
}

.main_text {
    font-size: 32px;
    color: white;
}

.main_text .ip_country {
    font-size: 40px;
    font-weight: bold;
}

#map {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10% 0 0 -25%;
}

@media only screen and (max-width: 768px) {
    #map{
        width: 70%;
        height: 40%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -13% 0 0 -35%;
    }
}

@media only screen and (max-width: 375px) {
    #map{
        width: 80%;
        height: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 15% 0 0 -40%;
    }
}

#kakaoAddr {
    display:block;
    margin-top:2px;
    font-weight: normal;
}
 .footer {
    z-index: 2;
    display: table;
    position: absolute;
    height: 13%;
  
    text-align: center;
    background-color: #263343;
    color: #35373e;
    width: 100%;
    bottom: 0;
  }

  .footer a {
    text-decoration: none;
    padding-right: 1vh;
  
    color: white;
  }
  .footer a:visited {
    text-decoration: none;
  }
  .footer a:hover {
    text-decoration: none;
  }
  .footer a:focus {
    text-decoration: none;
  }
  .footer a:hover,
  .footer a:active {
    text-decoration: none;
  }
  
  .footer_inner {
    display: table-cell;
    vertical-align: middle;
  }

  .footer_inner .api_by {
    color: #777777;
  }
.loader {
  display: table;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #191f26;
}

.loader_gif {
  display: table-cell;
  vertical-align: middle;
}
